<template>
  <div
    id="addStore"
    v-loading.fullscreen.lock="isLoading"
    style="padding: 0% 5%"
  >
    <div slot="header">
      <span v-if="storeId" class="maintitle">編輯商店</span>
      <span v-else class="maintitle">新增商店</span>
    </div>

    <el-divider></el-divider>

    <el-form
      ref="form"
      :rules="rules"
      :model="store"
      label-width="250px"
      label-position="left"
    >
      <el-form-item label="商店名稱" class="columnDist" prop="name" required>
        <el-input
          v-model="store.name"
          placeholder="請輸入商店名稱"
          style="width: 50%"
        ></el-input>
      </el-form-item>

      <el-form-item label="商店信箱" class="columnDist" prop="email" required>
        <el-input
          v-model="store.email"
          placeholder="請輸入商店電子信箱"
          style="width: 50%"
        ></el-input>
      </el-form-item>

      <el-form-item label="國別" class="columnDist" prop="countryCode" required>
        <el-select v-model="store.countryCode" placeholder="請選擇國別">
          <el-option
            v-for="item in countryOptions"
            :key="item.countryCode"
            :label="item.countryName"
            :value="item.countryCode"
          >
          </el-option>
        </el-select>
      </el-form-item>

      <el-form-item label="時區" class="columnDist" prop="timeZone" required>
        <el-select v-model="store.timeZone" placeholder="請選擇時區">
          <el-option
            v-for="item in timeZoneOptions"
            :key="item.name"
            :label="item.area"
            :value="item.name"
          >
          </el-option>
        </el-select>
      </el-form-item>

      <el-form-item
        label="商店類型"
        prop="channelType"
        class="columnDist"
        required
      >
        <el-radio-group v-model="store.channelType">
          <el-radio :label="'ONLINE'">線上</el-radio>
          <el-radio :label="'OFFLINE'">實體</el-radio>
        </el-radio-group>
      </el-form-item>

      <!-- <el-form-item
        label="發票開立方式"
        prop="isInvoiceAuto"
        class="columnDist"
        required
      >
        <el-radio-group v-model="store.isInvoiceAuto">
          <el-radio :label="true">自動開立</el-radio>
          <el-radio :label="false">手動開立</el-radio>
        </el-radio-group>
      </el-form-item> -->

      <el-form-item label="支付方式" class="columnDist">
        <el-table
          ref="checkTable"
          :data="tableData"
          border
          stripe
          empty-text="暫無數據"
          style="width: 80%"
          type="selection"
          :row-key="rowKeyPaymentTool"
          highlight-current-row
          @selection-change="handleSelectionChange"
        >
          <el-table-column
            type="selection"
            min-width="5%"
            header-align="center"
            align="center"
          ></el-table-column>
          <el-table-column
            prop="name"
            label="支付方式"
            min-width="25%"
            header-align="center"
            align="center"
          ></el-table-column>
        </el-table>
      </el-form-item>

      <el-form-item label="是否抓取提領資料" class="columnDist" required>
        <el-switch v-model="store.isOpenReportFoundJob"> </el-switch>
      </el-form-item>

      <el-form-item
        label="人名格式"
        prop="personNameFormat"
        class="columnDist"
        required
      >
        <el-radio-group v-model="store.personNameFormat">
          <el-radio :label="'LastNameFirstName'">LastNameFirstName</el-radio>
          <el-radio :label="'FirstNameLastName'">FirstNameLastName</el-radio>
        </el-radio-group>
      </el-form-item>

      <span class="columnDist2">外部系統設定</span>
      <br />
      <br />

      <el-form-item label="聯絡人api" class="columnDist" required>
        <el-switch v-model="store.isOpenFrontPayerApi"> </el-switch>
      </el-form-item>

      <el-form-item label="結帳api" class="columnDist" required>
        <el-switch v-model="store.isOpenDirectCheckoutApi"> </el-switch>
      </el-form-item>

      <el-form-item label="訂單建立時呼叫" class="columnDist">
        <el-input
          v-model="store.orderCreateUrl"
          placeholder="請輸入URL"
          style="width: 50%"
        ></el-input>
      </el-form-item>

      <el-form-item label="訂單付款完成呼叫" class="columnDist">
        <el-input
          v-model="store.orderFinishUrl"
          placeholder="請輸入URL"
          style="width: 50%"
        ></el-input>
      </el-form-item>

      <el-form-item
        label="訂單虛擬帳號匯款完成呼叫(藍新使用)"
        class="columnDist"
      >
        <el-input
          v-model="store.newebPayVaccFinishUrl"
          placeholder="請輸入URL"
          style="width: 50%"
        ></el-input>
      </el-form-item>

      <el-form-item label="退款完成呼叫" class="columnDist">
        <el-input
          v-model="store.refundFinishUrl"
          placeholder="請輸入URL"
          style="width: 50%"
        ></el-input>
      </el-form-item>

      <span class="columnDist2">金流設定</span>
      <br />
      <br />

      <el-form-item label="金流" prop="provider" class="columnDist" required>
        <el-radio-group v-model="store.provider">
          <el-radio :label="'NewebPay'">藍新</el-radio>
          <el-radio :label="'Stripe'">stripe</el-radio>
          <el-radio :label="'NoPay'">無</el-radio>
        </el-radio-group>
      </el-form-item>

      <div v-show="store.provider == 'NewebPay'">
        <span class="columnDist2"></span>

        <el-form-item label="藍新 API串接金鑰" class="columnDist" required>
          <el-radio-group v-model="store.newebpayType" @change="choseLui">
            <el-radio label="PROD">使用正式金流</el-radio>
            <el-radio label="TEST">使用測試金流</el-radio>
          </el-radio-group>
        </el-form-item>

        <div v-show="store.newebpayType == 'PROD'">
          <el-form-item label="MerchantId" required>
            <el-input
              v-model="store.newebpayMerchantId"
              placeholder="請輸入"
              style="width: 75%"
            ></el-input>
          </el-form-item>

          <el-form-item label="HashKey" required>
            <el-input
              v-model="store.newebpayKey"
              placeholder="請輸入"
              style="width: 75%"
            ></el-input>
          </el-form-item>

          <el-form-item label="HashIV" required>
            <el-input
              v-model="store.newebpayIv"
              placeholder="請輸入"
              style="width: 75%"
            ></el-input>
          </el-form-item>

          <el-form-item label="">
            <el-button
              type="success"
              @click="testExternalSysLogin()"
              :loading="testLoading"
              >測試連線</el-button
            >
          </el-form-item>
        </div>
      </div>

      <div v-show="store.provider == 'Stripe'">
        <span class="columnDist2">Stripe API串接金鑰</span>
        <br />
        <br />
        <el-form-item label="publishable_key" required>
          <el-input
            v-model="store.stripePublishableKey"
            placeholder="請輸入"
            style="width: 75%"
          ></el-input>
        </el-form-item>

        <el-form-item label="secret_key" required>
          <el-input
            v-model="store.stripeSecretKey"
            placeholder="請輸入"
            style="width: 75%"
          ></el-input>
        </el-form-item>

        <!-- <el-form-item label="">
          <el-button
            type="success"
            @click="testExternalSysLogin()"
            :loading="testLoading"
            >測試連線</el-button
          >
        </el-form-item> -->
      </div>
    </el-form>

    <br />
    <br />

    <el-form>
      <el-form-item>
        <div class="dialog-footer">
          <el-button v-if="storeId" type="primary" @click="addUpdate"
            >更新</el-button
          >
          <el-button v-else type="primary" @click="addUpdate">新增</el-button>
        </div>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import API from "../../api/";
import { Message } from "element-ui";
import utils from "../../utils";

export default {
  name: "CustomerStoreAdd",
  props: {
    companyId: String,
    storeId: String,
    clickTime: Date,
  },

  data() {
    return {
      isLoading: false,
      testLoading: false,

      store: {
        name: null,
        email: null,
        countryCode: null,
        timeZone: null,
        channelType: null,
        isOpenReportFoundJob: false,
        personNameFormat: null,
        isOpenFrontPayerApi: null,
        isOpenDirectCheckoutApi: null,
        orderCreateUrl: null,
        orderFinishUrl: null,
        newebPayVaccFinishUrl: null,
        refundFinishUrl: null,
        provider: null,
        newebpayType: null,
        newebpayMerchantId: null,
        newebpayKey: null,
        newebpayIv: null,
        stripePublishableKey: null,
        stripeSecretKey: null,
        paymentToolApi: [],
      },

      countryOptions: utils.Country,

      timeZoneOptions: utils.TimeZone,

      //支付工具
      tableData: [],
      multipleSelection: [],
      rules: {
        name: [{ required: true, message: "必填", trigger: "blur" }],
        email: [
          { required: true, message: "必填", trigger: ["blur", "change"] },
          {
            type: "email",
            message: "請輸入正確的電子信箱",
            trigger: ["blur", "change"],
          },
        ],
        countryCode: [{ required: true, message: "必填", trigger: "change" }],
        timeZone: [{ required: true, message: "必填", trigger: "change" }],
        personNameFormat: [
          { required: true, message: "必填", trigger: "change" },
        ],
        provider: [{ required: true, message: "必填", trigger: "change" }],
      },
    };
  },

  watch: {
    clickTime: function (newVal) {
      this.openWindow();
    },

    "store.provider": function (newVal) {
      if (newVal == "NewebPay") {
        this.store.stripePublishableKey = null;
        this.store.stripeSecretKey = null;
      } else if (newVal == "Stripe") {
        this.store.newebpayType = null;
      } else if (newVal == "NoPay") {
        this.store.stripePublishableKey = null;
        this.store.stripeSecretKey = null;
        this.store.newebpayType = null;
      }
    },
  },

  created() {
    this.openWindow();
  },

  methods: {
    async openWindow() {
      // 1. 清除 預設勾選的支付工具
      this.multipleSelection = [];
      this.$nextTick(function () {
        this.$refs["checkTable"].clearSelection();
      });

      // 2. 取得 支付工具
      this.isLoading = true;
      API.store
        .getToolByChannelType(null)
        .then((res) => {
          this.tableData = res.data;

          if (this.storeId) {
            API.customer.queryStore(this.storeId).then((res2) => {
              // console.log(res2.data);
              Object.assign(this.store, res2.data);
              this.store.paymentToolApi.forEach((paymentTool) => {
                const row = this.tableData.find(
                  (row) => row.id == paymentTool.id
                );
                if (row) this.$refs["checkTable"].toggleRowSelection(row, true);
              });

              this.isLoading = false;
            });
          } else {
            // 資料回到預設值
            this.store.name = null;
            this.store.email = null;
            this.store.countryCode = null;
            this.store.timeZone = null;
            this.store.channelType = null;
            this.store.isOpenReportFoundJob = false;
            this.store.personNameFormat = null;
            this.store.isOpenFrontPayerApi = null;
            this.store.isOpenDirectCheckoutApi = null;
            this.store.orderCreateUrl = null;
            this.store.orderFinishUrl = null;
            this.store.newebPayVaccFinishUrl = null;
            this.store.refundFinishUrl = null;
            this.store.provider = null;
            this.store.newebpayType = null;
            this.store.newebpayMerchantId = null;
            this.store.newebpayKey = null;
            this.store.newebpayIv = null;
            this.store.stripePublishableKey = null;
            this.store.stripeSecretKey = null;
            this.store.paymentToolApi = [];

            this.$refs["form"].resetFields();

            this.isLoading = false;
          }
        })
        .catch((e) => {
          console.log("e:", e);
          console.log(`query error`);
          Message({
            message: "查詢失敗!",
            type: "error",
            duration: 3 * 1000,
          });
          this.isLoading = false;
        });
    },
    // 新增
    addUpdate() {
      this.$refs["form"].validate((valid) => {
        if (valid) {
          // 設定商店的支付方式檢查防呆
          if (this.multipleSelection.length <= 0) {
            Message({
              message: "支付方式至少選擇一種",
              type: "warning",
            });
            return;
          } else {
            this.store.paymentToolApi = this.multipleSelection;
          }

          if (this.store.provider == "NewebPay") {
            if (this.store.newebpayType) {
              if (this.store.newebpayType == "PROD") {
                if (utils.Check.isNull(this.store.newebpayMerchantId)) {
                  Message({
                    message: "藍新 MerchantId必填",
                    type: "warning",
                  });
                  return;
                }
                if (utils.Check.isNull(this.store.newebpayKey)) {
                  Message({
                    message: "藍新 HashKey必填",
                    type: "warning",
                  });
                  return;
                }
                if (utils.Check.isNull(this.store.newebpayIv)) {
                  Message({
                    message: "藍新 HashIV必填",
                    type: "warning",
                  });
                  return;
                }
              }
            } else {
              Message({
                message: "藍新 API串接金鑰必填",
                type: "warning",
              });
              return;
            }
          }

          if (this.store.provider == "Stripe") {
            if (utils.Check.isNull(this.store.stripePublishableKey)) {
              Message({
                message: "Stripe publishable_key必填",
                type: "warning",
              });
              return;
            }
            if (utils.Check.isNull(this.store.stripeSecretKey)) {
              Message({
                message: "Stripe secret_key必填",
                type: "warning",
              });
              return;
            }
          }

          this.saveData(this.store);
        }
      });
    },
    // 儲存Data
    saveData(data) {
      console.log("store: ", data);
      this.isLoading = true;

      if (this.storeId) {
        API.customer
          .updateStore(this.storeId, data)
          .then((newP) => {
            Message({
              message: "更新成功!",
              type: "success",
            });
            this.$emit("finish");
            this.isLoading = false;
          })
          .catch((err) => {
            // 1. loading 結束
            this.isLoading = false;
          });
      } else {
        API.customer
          .addStore(this.companyId, data)
          .then((newP) => {
            Message({
              message: "新增成功!",
              type: "success",
            });
            this.$emit("finish");
            this.isLoading = false;
          })
          .catch((err) => {
            // 1. loading 結束
            this.isLoading = false;
          });
      }
    },
    rowKeyPaymentTool(row) {
      return row.id;
    },
    // 更改paymentTool
    handleSelectionChange(val) {
      this.multipleSelection = val;
      console.log(this.multipleSelection);
    },

    //藍新API串接金鑰_使用澈見代收預設值代入
    choseLui(selectType) {
      if (selectType == "TEST") {
        this.store.newebpayMerchantId = "MS36059278";
        this.store.newebpayKey = "hBYd2JQpSJgAJ3i7vcABMnizGmrj3wUN";
        this.store.newebpayIv = "X95kQvn3mVdKxzZl";
      } else {
        this.store.newebpayMerchantId = null;
        this.store.newebpayKey = null;
        this.store.newebpayIv = null;
      }
    },

    testExternalSysLogin() {
      if (utils.Check.isNull(this.store.newebpayMerchantId)) {
        Message({
          message: "請輸入MerchantId!",
          type: "warning",
        });
        return;
      }

      if (utils.Check.isNull(this.store.newebpayKey)) {
        Message({
          message: "請輸入Key!",
          type: "warning",
        });
        return;
      }

      if (utils.Check.isNull(this.store.newebpayIv)) {
        Message({
          message: "請輸入Iv!",
          type: "warning",
        });
        return;
      }

      this.testLoading = true;
      API.customer
        .testNewebpayStripe({
          ezPayMerchantId: this.store.newebpayMerchantId,
          ezPayKey: this.store.newebpayKey,
          ezPayIv: this.store.newebpayIv,
        })
        .then((res) => {
          if (res.data.testConnection) {
            Message({
              message: "連線成功!",
              type: "success",
            });
          } else {
            Message({
              message: "連線失敗!",
              type: "warning",
            });
          }

          this.testLoading = false;
        })
        .catch((err) => {
          Message({
            message: "測試錯誤!",
            type: "error",
          });

          this.testLoading = false;
        });
    },
  },
};
</script>

<style scoped>
.maintitle {
  font-size: 18px;
  font-weight: bold;
}
.title {
  font-size: 14px;
  font-weight: bold;
  color: #606266;
}

.columnDist {
  font-weight: bold;
  font-size: 14px;
  color: #606266;
}

.columnDist2 {
  font-weight: bold;
  font-size: 20px;
  margin-left: 0px;
  margin-top: 0px;
  margin-bottom: 10px;
}

.boxDist {
  margin-left: 20px;
}

.dialog-footer {
  text-align: center;
  margin-top: 50px;
}

::v-deep .el-form-item__label {
  margin-bottom: 0px;
  padding: 0px;
}
</style>
