<template>
  <div v-loading.fullscreen.lock="isLoading">
    <el-card>
      <div slot="header" style="line-height: 40px">
        <span class="title">商店列表</span>

        <el-button
          style="float: right; margin: 5px"
          type="primary"
          @click="openStoreEdit()"
          >新增商店
        </el-button>
      </div>
      <el-row>
        <span style="margin-left: 10px">{{ `組織名稱：${name}` }}</span>
        <span style="margin-left: 20px">{{ `統編：${ubn}` }} </span>
        <span
          v-if="status == '啟用'"
          class="span-success"
          style="margin-left: 20px"
          >{{ `狀態：${status}` }}
        </span>
        <span v-else class="span-danger" style="margin-left: 20px"
          >{{ `狀態：${status}` }}
        </span>
      </el-row>
      <el-divider></el-divider>
      <el-table :data="tableData" id="customerResult" empty-text="暫無數據">
        <el-table-column
          show-overflow-tooltip
          prop="name"
          label="商店名稱"
          width="220"
          header-align="center"
          align="center"
        >
        </el-table-column>

        <el-table-column
          prop="channelType"
          label="商店種類"
          width="180"
          header-align="center"
          align="center"
        >
          <template slot-scope="scope">
            <span>
              {{
                scope.row.channelType == "OFFLINE"
                  ? "實體店面"
                  : scope.row.channelType == "ONLINE"
                  ? "網路商店"
                  : ""
              }}
            </span>
          </template>
        </el-table-column>

        <el-table-column
          prop="provider"
          label="金流類型"
          min-width="180"
          header-align="center"
          align="center"
        >
          <template slot-scope="scope">
            <span>
              {{
                scope.row.provider == "NewebPay"
                  ? "藍新"
                  : scope.row.provider == "Stripe"
                  ? "Stripe"
                  : scope.row.provider == "NoPay"
                  ? "無"
                  : ""
              }}
            </span>
          </template>
        </el-table-column>

        <el-table-column
          label="操作"
          min-width="120"
          header-align="center"
          align="center"
        >
          <template slot-scope="scope">
            <el-button
              size="mini"
              v-on:click="openStoreEdit(scope.row.id)"
              type="primary"
              >編輯
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </el-card>

    <!--編輯商店-->
    <el-dialog
      :visible.sync="dialogUse.storeVisible"
      width="60%"
      top="20px"
      :close-on-click-modal="false"
      @closed="closeDialog()"
    >
      <CustomerStoreEdit
        :companyId="dialogUse.companyId"
        :storeId="dialogUse.storeId"
        :clickTime="dialogUse.clickTimestore"
        @finish="closeDialog()"
      />
    </el-dialog>
  </div>
</template>

<script>
import API from "../../api";
import CustomerStoreEdit from "./CustomerStoreEdit.vue";

export default {
  name: "CustomerManage",
  components: {
    CustomerStoreEdit,
  },

  data() {
    return {
      isLoading: false, // 是否加載數據
      id: this.$route.query.id,
      name: "",
      ubn: "",
      status: "",
      tableData: [],
      dialogUse: {
        storeVisible: false,
        companyId: null,
        storeId: null,
        clickTimestore: null,
      },
    };
  },

  created() {
    this.query();
  },

  methods: {
    query() {
      this.isLoading = true;
      API.customer
        .queryById(this.id)
        .then((res) => {
          this.name = res.data.name;
          this.ubn = res.data.ubn;
          this.status =
            res.data.status == "ENABLED"
              ? "啟用"
              : res.data.status == "DISABLED"
              ? "停用"
              : "";
          this.tableData = res.data.storeList ?? [];
          this.isLoading = false;
        })
        .catch((e) => {
          console.log(`query error`);
          Message({
            message: "查詢失敗!",
            type: "error",
            duration: 3 * 1000,
          });
          this.isLoading = false;
        });
    },

    // 新增、編輯商店
    openStoreEdit(id) {
      this.dialogUse.storeId = id;
      this.dialogUse.companyId = this.id;
      this.dialogUse.clickTimestore = new Date();
      this.dialogUse.storeVisible = true;
    },

    //關閉彈窗(新增/編輯)
    closeDialog() {
      this.dialogUse.storeId = null;
      this.dialogUse.companyId = null;
      this.dialogUse.clickTimestore = null;
      this.dialogUse.storeVisible = false;
      this.query();
    },
  },
};
</script>

<style scoped>
.title {
  font-size: 16px;
  font-weight: bold;
}

.pagination {
  margin-top: 10px;
  margin-left: 35%;
}

::v-deep .span-secondary {
  color: var(--secondary) !important;
}
::v-deep .span-success {
  color: var(--success) !important;
}
::v-deep .span-warning {
  color: var(--warning) !important;
}
::v-deep .span-danger {
  color: var(--danger) !important;
}

::v-deep .el-table__expanded-cell {
  background-color: #ebedef;
  padding: 0px 0px;
}
</style>
